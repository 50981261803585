import { render, staticRenderFns } from "./moreoptions.vue?vue&type=template&id=f37dd61e&scoped=true"
import script from "./moreoptions.vue?vue&type=script&lang=js"
export * from "./moreoptions.vue?vue&type=script&lang=js"
import style0 from "./moreoptions.vue?vue&type=style&index=0&id=f37dd61e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f37dd61e",
  null
  
)

export default component.exports